/** @jsx jsx */
import { jsx } from '@emotion/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import React, { useState, useEffect } from 'react';
import { background, containerCard, linkContainerStyle } from '../assets/styles/CardSwiperInstagram.styles';
import { Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';

function InstagramSlide() {
  const [posts, setPosts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const accessToken = 'IGQWRNZAmFUR1F1YUttUHFpdGNoSkZAoTzRwN1g5ZAmlNQnRmeUF5Rmo0d1ZACeVN1dVlHN0pQT09GLU1pUmQwSDIwaVU1X2VkTXdkUFRiRHJXbVNqT0VSNmRnYXYxTHRZATkxVT1lzblB4aTlVMFlubzRRSnBNcXlBY0kZD';
        const userId = '17841407393827127';

        const response = await fetch(
          `https://graph.instagram.com/${userId}/media?fields=id,caption,media_url,media_type,thumbnail_url,permalink&access_token=${accessToken}`
        );

        if (!response.ok) {
          throw new Error('Falha ao carregar os posts do Instagram');
        }

        const data = await response.json();
        
        setPosts(data.data ? data.data.slice(0, 8) : []); 
      } catch (error) {
        console.error('Erro ao buscar posts do Instagram:', error);
      }
    };

    fetchInstagramPosts();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 500);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return (
    <>
    <div css={background} data-aos="fade-left">
      <div css={containerCard}>
        <Swiper
          slidesPerView={'auto'}  
          spaceBetween={isMobile ? 0 : 20}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          mousewheel={true}
          modules={[Autoplay]}
          className='swiper'
        >
          {posts.map((post) => {
              const isVideo = post.media_type === 'VIDEO'
              const mediaUrl = isVideo ? post.thumbnail_url : post.media_url
              return (
            <SwiperSlide key={post.id}>
              <div className='swiper-slide__container'>
                <div className='photoProfile'>
                  <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                    <img
                      src={mediaUrl}
                    />
                  </a>
                </div>
              </div>
            </SwiperSlide>
              )
          }
          )}
        </Swiper>
        <div className='swiper-pagination' />
        <div id="desafios"></div>
      </div>
    </div>
    <Container fluid css={linkContainerStyle}>
        <Link to="https://www.instagram.com/kingdomschooloficial/" target="_blank" rel="noopener noreferrer">Siga a Kingdom</Link>
    </Container>
    </>
  );
}

export default InstagramSlide;
